export * from './campaign-gallery.model';
export * from './campaign-promoted-state.model';
export * from './campaign-result-methods';
export * from './campaign-source.model';
export * from './campaign-status';
export * from './period-type';

export type SizeUnit = 'Square' | 'SquareMeter' | 'Acre' | 'Hectare' | 'SquareFeet';

export const ListingImageType = {
  FloorPlan: 'FloorPlan',
  Other: 'Other',
  Hero: 'Hero',
  CustomReviewImage: 'CustomReviewImage',
} as const;

export type ListingImageType = (typeof ListingImageType)[keyof typeof ListingImageType];
