import { DestroyRef, inject, Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { CurrentUser, CurrentUserService } from '@rma/accounts/user';
import { DeviceIdentificationService } from '@rma/generic/util/device-identification';
import { PlatformService } from '@rma/generic/util/environment';
import { LDContextCommon, LDMultiKindContext } from 'launchdarkly-js-sdk-common';
import { FeatureProviderService } from '../feat-feature-provider/feature-provider.service';
@Injectable({
  providedIn: 'root',
})
export class FeatureUserService {
  private readonly currentUserService = inject(CurrentUserService);
  private readonly deviceIdService = inject(DeviceIdentificationService, { optional: true });
  private readonly featureProviderService = inject(FeatureProviderService);
  private readonly platformService = inject(PlatformService);
  private readonly destroyRef = inject(DestroyRef);

  public initialise() {
    if (this.platformService.isPlatformServer) {
      return;
    }

    this.currentUserService.currentUser$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((x) => this.identifyUser(x));
  }

  private identifyUser(currentUser: CurrentUser | null) {
    const deviceId = this.deviceIdService?.getDeviceId();

    const userContext = this.getLdUserContext(currentUser);
    const deviceContext = this.getLdDeviceContext(deviceId, Boolean(userContext?.anonymous));

    const context: LDMultiKindContext = {
      kind: 'multi',
    };

    if (deviceContext) {
      context['device'] = deviceContext;
    }

    if (userContext) {
      context['user'] = userContext;
    }

    this.featureProviderService.identify(context);
  }

  private getLdDeviceContext(deviceId: string | undefined, isAnonymous: boolean): LDContextCommon | null {
    //If this works, we should extend the custom with browser information using bowser or ua parser
    return deviceId
      ? {
          key: deviceId,
          anonymous: isAnonymous,
          custom: {},
        }
      : null;
  }

  private getLdUserContext(currentUser: CurrentUser | null): LDContextCommon | null {
    return currentUser
      ? {
          key: currentUser.userId,
          name: currentUser.name,
          firstName: currentUser.firstname,
          lastName: currentUser.lastname,
          avatar: currentUser.profileImage,
          custom: {
            agencies: currentUser?.agencies?.map((agency) => agency.name) as string[],
          },
        }
      : { anonymous: true };
  }
}
